import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/place.scss"

const Place = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsLocationpage?.seoMetaTags} page="Paikka ja majoitus">
    <div className="place">
      <div className="content">
        <h2>{data.datoCmsLocationpage.title}</h2>
        <div className="markDown"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsLocationpage.bodyNode.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  </Layout>
)
export const query = graphql`
  query locationQuery {
    datoCmsLocationpage {
      seoMetaTags{
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Place
